@import "../../assets/scss/variable.scss";

.body-content {
  height: calc(100vh - 161px);
  background-color: $VistaWhite;
  position: relative;
  top: 56px;

  .left-color {
    padding: 0px 0px 0 20px;
    width: 200px;

    .animate {
      left: 0px;
      position: relative;
      animation: custom-animation-out 4s ease-in-out;
    }

    .color-round {
      height: 52px;
      width: 52px !important;
      transform: scale(1);
      display: block;

      // margin: 10px 0 10px;
      &::before {
        height: calc(100% + 10px);
        width: calc(100% + 10px);
        border-radius: 50%;
        content: "";
        position: absolute;
        z-index: 1;
        top: 50%;
        border: 1px solid $LavenderGrey;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 0;
      }

      .color-name {
        opacity: 0;
        position: relative;
        transform: translate(100%, 0%);
        left: 100%;
        text-align: left;
        transition: 0.5s;
        margin-left: 20px;
      }

      &.active {
        transform: scale(1.1);

        &::before {
          opacity: 1;
        }

        .color-name {
          opacity: 1;
        }
      }

      &:hover {
        .color-name {
          opacity: 1;
          transition: 0.5s;
        }
      }
    }

    .slick-slider {
      height: 100%;
      animation: custom-animation-out 5s ease-in-out;
      left: 0px;
      position: relative;

      .slick-list {
        padding: 0px 0px 0px 8px;

        .slick-slide {
          padding: 9px 0 9px;
        }

        .slick-track {
          height: auto !important;
        }
      }

      .slick-arrow {
        color: $Black;
      }

      .slick-prev {
        top: 0;
        position: relative;
        transform: rotate(90deg);
        left: 20px;
        width: 30px;
        height: 30px;
        animation: UpDown 1s linear infinite;

        &.slick-disabled {
          opacity: 0;
          z-index: -1;
        }
      }

      .slick-next {
        top: 0;
        left: 20px;
        position: relative;
        transform: rotate(90deg);
        width: 30px;
        height: 30px;
        animation: UpDown 1s linear infinite;

        &.slick-disabled {
          opacity: 0;
          z-index: -1;
        }
      }
    }
  }

  &::-webkit-scrollbar {
    width: 0px;
  }

  .center-image {
    width: calc(100% - 190px);

    .canvas-part {
      .canvas-part-dot {
        span {
          min-width: 11px;
          min-height: 11px;
          background-color: $LightGrey;
          width: 11px;
          height: 11px;
          display: inline-block;
          margin: 0 23px 0 0;
          border-radius: 50%;
          cursor: pointer;

          &.active {
            background-color: $Black;
          }

          &:nth-child(7) {
            margin: 0;
          }
        }

        .canvas-part-right-arrow {
          width: 30px;
          height: 30px;
          position: relative;
          top: -2px;
          right: -10px;
          color: $Black;
        }

        .canvas-part-left-arrow {
          width: 30px;
          height: 30px;
          position: relative;
          top: -2px;
          left: -10px;
          color: $Black;
        }
      }

      &.alteryx-dot{
        .canvas-part-dot {
          span.active{
              background-color: $AlterBlue;
            }
          }

      }
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }

  .animate {
    transition: all .2s ease-in;
  }

  .page-title {
    color: $RubberDuckyYellow;
  }

  .img-hover-shadow {
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25)
  }

  .left-pattern {
    width: 86px;
    text-align: center;

    .pattern-round {
      width: 71px;
      height: 71px;
      margin-bottom: 37px;

      &::before {
        height: 86px;
        width: 86px;
        border-radius: 50%;
        content: "";
        position: absolute;
        z-index: 1;
        top: 50%;
        border: 1px solid $LavenderGrey;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 0;
      }

      .pattern-name {
        display: none;
        position: absolute;
        transform: translate(100%, -50%);
        top: 50%;
        left: 15px;
        width: 100%;
        text-align: left;
      }

      &.active {
        &::before {
          opacity: 1;
        }

        .pattern-name {
          display: block;
        }
      }

      &:hover {
        .pattern-name {
          display: block;
          transition: 0.5s;
        }
      }

      &.animate {
        left: 0px;
        position: relative;
        animation: custom-animation-out 4s ease-in-out;
      }
    }
  }

  .right-pattern {
    width: 75px;
    text-align: left;
    position: fixed;
    right: 0;
    overflow: hidden;
    transition: width 0.5s ease-in;

    .right-pattern-text {
      span {
        opacity: 0;
        transition: opacity 0.5s ease-in;
      }
    }

    &:hover {
      width: 178px;

      span {
        opacity: 1;
      }
    }
  }
}

.icon-360-button {
  position: absolute;
  left: 50%;
  top: 50%;
  left: 0;
  right: 0;

  .icon-360 {
    background-color: $PeachOrange;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 15%);
    width: max-content;
  }
}

.branding {
  max-width: 160px;
}

.about-modal {
  img {
    height: 286px;
    object-fit: cover;
    object-position: center;
  }

  iframe {
    width: 100%;
    height: 286px;
  }
}

.color-footer-canvas {
  min-height: 125px;
  height: auto !important;

  .left-color {
    .slick-slider {
      .slick-arrow {
        color: $Black;
      }

      .slick-list {
        padding-bottom: 6px;
      }
    }

    .color-round {
      height: 37px;
      width: 37px;
      transform: scale(1);
      margin: 7px auto 2px 7px;

      &::before {
        height: calc(100% + 10px);
        width: calc(100% + 10px);
        border-radius: 50%;
        content: "";
        position: absolute;
        z-index: 1;
        top: 50%;
        border: 1px solid $LavenderGrey;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 0;
      }

      &.active {
        transform: scale(1.1);

        &::before {
          opacity: 1;
        }
      }

      &.animate {
        left: 0px;
        position: relative;
        transition: all 0.2s ease-in;
      }

      .color-name {
        display: none;
      }
    }

    .left-pattern {
      .pattern-round {
        width: 51px;
        height: 51px;
        margin-right: 15px;
        padding: 0;

        .pattern-name {
          display: none;
        }

        &::before {
          height: 66px;
          width: 66px;
          border-radius: 50%;
          content: "";
          position: absolute;
          z-index: 1;
          top: 50%;
          border: 1px solid $LavenderGrey;
          left: 50%;
          transform: translate(-50%, -50%);
          opacity: 0;
        }

        &.active {
          &::before {
            opacity: 1;
          }
        }
      }
    }

    .input-tab {
      .nav-item {
        a {
          border-radius: 20px;

          &.active {
            border: 1px solid $Black;
          }
        }
      }
    }
  }
}

.welcome-modal {
  border-bottom-left-radius: calc(0.75rem - 1px);
  border-bottom-right-radius: calc(0.75rem - 1px);
}

@keyframes UpDown {
  0% {
    top: 5px;
  }

  50% {
    top: 0;
  }

  100% {
    top: 5px;
  }
}

.Phone {
  .dropdown {
    button {
      background-color: transparent;
      border: none;
      width: 80% !important;

      &::after {
        content: none;
      }

      .color_circle {
        margin: 0 !important;
      }
    }

    .dropdown-toggle {
      background-color: transparent;
    }
  }
}

.color_circle {
  width: 30px;
  height: 30px;
  background-color: $white;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 2px $circle-border;
  margin: 0px 14px;
}

.footer-content {
  .slick-slider {

    .slick-prev,
    .slick-next {
      color: $Black;
      z-index: 9;
    }

    svg {
      height: 23px;
      width: 14px;
    }
  }

  .info-icon {
    font-size: 28px;
  }

  .color-pattern-tab {
    .left-color {
      .color-round {
        height: 40px;
        width: 40px;

        &::before {
          height: 52px;
          width: 52px;
          border-radius: 50%;
          content: "";
          position: absolute;
          z-index: 1;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          border: 1px solid $LavenderGrey;
          opacity: 0;
        }

        .color-name {
          display: none;
          position: absolute;
          right: 0;
          transform: translate(-50%, 10px);
          top: 100%;
          bottom: 0;
          text-align: center;
          margin: 0 auto;
          width: max-content;
          left: 50%;
        }

        &.active {
          &::before {
            opacity: 1;
          }

          .color-name {
            display: block;
          }
        }

        &:hover {
          .color-name {
            display: block;
          }
        }
      }
    }

    .left-pattern {
      .pattern-round {
        width: 40px;
        height: 40px;

        &::before {
          height: 52px;
          width: 52px;
          border-radius: 50%;
          content: "";
          position: absolute;
          z-index: 1;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          border: 1px solid $LavenderGrey;
          opacity: 0;
        }

        .pattern-name {
          display: none;
          position: absolute;
          right: 0;
          transform: translate(-50%, 10px);
          top: 100%;
          bottom: 0;
          text-align: center;
          margin: 0 auto;
          width: max-content;
          left: 50%;
        }

        &.active {
          &::before {
            opacity: 1;
          }

          .pattern-name {
            display: block;
          }
        }

        &:hover {
          .pattern-name {
            display: block;
          }
        }
      }
    }
  }

  .nav-item {
    .border-left-radius {
      border-radius: 20px 0 0 20px;
      border: 1px solid $Black;
      color: $Black;
    }

    .border-right-radius {
      border-radius: 0px 20px 20px 0px;
      border: 1px solid $Black;
      color: $Black;
    }

    a.active {
      background-color: $Black;
      color: $white;
    }
  }

  .input-tab {
    .nav-item {
      a {
        border: 1px solid transparent;
        border-radius: 20px;
      }

      a.active {
        border: 1px solid black;
      }
    }
  }

  .slider-list {
    cursor: pointer;

    .slider-list-menu {
      display: none;
      position: absolute;
      width: 100%;
      bottom: 0px;
      border-radius: 4px;
      box-shadow: 0px 4px 4px rgb(0, 0, 0, 0.25);
      width: 155px;
      left: 50%;
      transform: translate(-50%, 0px);
      z-index: 9;

      ul {
        li {
          color: $DarkGrey;
          transition: 0.5s;

          &:hover {
            background-color: $Green;
            color: $white;
          }

          &.active {
            background-color: $Green;
            color: $white;
          }
        }
      }
    }

    &.active {
      .slider-list-menu {
        display: block;
      }
    }
  }

  .footer-all-option {
    .alteryx-footer-icon {
      cursor: pointer;
      transition: 0.5s;
      max-width: 50px;
      min-width: 66px;

      svg {
        margin-bottom: 6px;

        path,
        ellipse {
          fill: $SilverChalice !important;
        }
      }

      p {
        opacity: 0;
      }
      &:hover {
        transition: 0.5s;

        svg {
          margin-bottom: 6px;

          path,
          ellipse {
            fill: $AlterBlue !important;
          }
        }

        p {
          transition: 0.5s;
          color: $AlterBlue !important;
          opacity: 1;
        }
      }

      &.active {
        transition: 0.5s;

        p {
          color: $AlterBlue !important;
          opacity: 1;
        }

        svg {

          path,
          ellipse {
            fill: $AlterBlue !important;
          }
        }
      }
    }
    .footer-icon {
      cursor: pointer;
      transition: 0.5s;
      max-width: 50px;
      min-width: 66px;

      svg {
        margin-bottom: 6px;

        path,
        ellipse {
          fill: $SilverChalice;
        }
      }

      p {
        opacity: 0;
      }

      &:hover {
        transition: 0.5s;

        svg {
          margin-bottom: 6px;

          path,
          ellipse {
            fill: $Black;
          }
        }

        p {
          transition: 0.5s;
          color: $Black;
          opacity: 1;
        }
      }

      &.active {
        transition: 0.5s;

        p {
          color: $Black;
          opacity: 1;
        }

        svg {

          path,
          ellipse {
            fill: $Black;
          }
        }
      }
    }
  }
}

.handcrafted-by-woolly-made-sec {
  .modal-content {
    background-color: $RoseWhite;

    .modal-body {
      .slick-slider {

        .slick-prev,
        .slick-next {
          color: $Black;
          width: 30px;
          height: 30px;
        }

        .slick-dots {
          li {
            button {
              &::before {
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }
}

.modification-offcanvas {
  height: auto !important;

  .offcanvas-body {
    img {
      border-radius: 15px;
      transition: 0.5s;

      &:hover {
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
      }
    }
  }

  .inspiration-content {
    .inspiration-col {
      .save-img {
        height: 256px;

        img {
          object-fit: cover;
        }
      }

      .inspiration-button {
        align-items: center;
        display: none;
        height: 100%;
        inset: 0;
        justify-content: center;
        position: absolute;
        width: 100%;

        button {
          border-radius: 50px;
        }
      }

      &:hover {
        .inspiration-button {
          display: flex;
          border-radius: 20px;
          box-shadow: 0 2px 10px 0 rgb(57 57 57 / 10%), 0 7px 72px 0 rgb(53 53 53 / 18%);
          transition: .5s cubic-bezier(.075, .82, .165, 1);
        }
      }
    }
  }
}

.tooltip {
  .tooltip-inner {
    background: none;
    color: $Black;
    font-weight: 500;
  }

  .tooltip-arrow {
    &::before {
      display: none;
    }
  }
}

.fade.show.tooltip.bs-tooltip-start,
.fade.show.tooltip.bs-tooltip-end,
.fade.show.tooltip.bs-tooltip-bottom {
  position: absolute;
  top: 0;
  text-align: center;
  transition: 0s;
  left: 0;
}

.global-backdrop {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  z-index: 1;
  position: fixed;
  align-items: center;
  justify-content: center;
  -webkit-tap-highlight-color: transparent;
  // background-color: aqua;
}

.img-box {
  // background-color: rosybrown;
  // width: 200px;
  height: 380px;
  display: flex;
  justify-content: center;
}

.img-cover {
  // width: 100%;
  // height: 125%;
  object-fit: cover;
  // margin: -80px 0;
}

@media only screen and (max-height: 576.98px) {
  .body-content {
    height: calc(100vh - 247px);
  }
}


@media screen and (max-height: 768px) {
  .body-content {
    .left-color {
      .color-round {
        height: 32px;
        width: 32px !important;
        transform: scale(1);

        // margin: 8px 0 10px;
        &::before {
          height: calc(100% + 7px);
          width: calc(100% + 7px);
        }

        &.active {
          transform: scale(1.25);
        }
      }

      .slick-slider {
        .slick-arrow {
          left: 10px;
        }

        .slick-prev {
          left: 10px;
        }
      }
    }

    .center-image {
      width: calc(100% - 190px);
    }
  }
}

@media only screen and (max-width: 1334.98px) and (max-height: 664.98px)  {
  .body-content {
    height: calc(100vh - 220px);
  }
}
@media only screen and (max-width: 1199.98px) {
  .body-content {
    height: calc(100vh - 190px);
  }
}
@media only screen and (max-width: 1024.98px) {
  .body-content {
    height: calc(100vh - 161px);
  }
}
@media only screen and (max-width: 1334.98px) and (max-height: 664.98px)  {
    .body-content {
      height: calc(100vh - 220px);
    }
  }
  @media only screen and (max-width: 1199.98px) {
    .body-content {
      height: calc(100vh - 190px);
    }
  }
  @media only screen and (max-width: 1180.98px) and (max-height: 820.98px)  {
    .body-content {
      height: calc(100vh - 161px);
    }
  }
  @media only screen and (max-width: 1024.98px) {
    .body-content {
      height: calc(100vh - 161px);
    }
  }
@media only screen and (max-width: 991.98px) {
  .footer-content {
    height: auto;

    .app-drawer {
      button {
        svg {
          font-size: 40px;
        }
      }
    }

    .color-pattern-hide {
      transition: 0.4s;
      transition-timing-function: ease-out;
      height: 0;
      overflow: hidden;
    }

    .color-pattern-hide.active {
      height: 100%;
      transition: 0.4s;
      transition-timing-function: ease-in;
      overflow: initial;
    }
  }

  .body-height {
    height: calc(100vh - 475px);

    .center-image {
      img {
        padding: 50px 0;
        height: calc(100vh - 450px);
        transition: all 0.4s linear;
      }
    }
  }

  .body-content {
    height: calc(100vh - 174px);

    .center-image {
      width: 100%;
      height: calc(100% - 60px);

      .canvas-part {
        position: fixed;
        bottom: 113px;
        left: 0;
        right: 0;
      }
    }
  }

  .icon-360-button {
    width: calc(100% - 0px);
  }

  .img-box {
    .img-cover {
      width: 100%;
    }

    height: 250px;
  }

  .footer-content {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;

    .footer-all-option {
      .footer-icon {
        max-width: fit-content;
        min-width: auto;

        p {
          opacity: 1;
          color: $Black;
        }

        svg {
          margin-bottom: 6px;

          path,
          ellipse {
            fill: $Black;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 820.98px) and (max-height: 1180.98px)  {
  .body-content {
    height: calc(100vh - 169px);
  }
}
@media only screen and (max-width: 576px) {
  .body-content {
    top: 61px;
  }
}

@media only screen and (max-width: 576px) {
  .modification-offcanvas {
    .inspiration-content {
      .inspiration-col {
        .inspiration-button {
          display: flex;
        }
      }
    }
  }
}

.slider-list {
  .invitation-blink {
    .slick-next {
      background-color: transparent;
      width: 52px;
      height: 52px;
      padding: 15px;
      border-radius: 50%;
      animation: blinker 3s forwards;
      animation-iteration-count: infinite;
      right: -44px;
    }
  }
}

@keyframes blinker {
  50% {
    background-color: $PaleBlueLily;
  }
}

@keyframes custom-animation-out {
  0% {
    left: -200px;
  }

  25% {
    left: 0px;
  }
}
.body-content {
  .left-pattern {
    .pattern-round {
      &:nth-child(2) {
        padding: 0 !important;
        border: none !important;

        img {
          width: 100%;
        }
      }
    }
  }
}