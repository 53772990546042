@import "../scss/variable.scss";

@font-face {
  font-family: 'hochelaga';
  src: url('../fonts/Hochelaga-Regular.otf');
}

@font-face {
  font-family: 'avenir';
  src: url('../fonts/AvenirLTStd-Book.otf');
}

@font-face {
  font-family: 'Avenir Heavy';
  src: url('../fonts/Avenir-Heavy.ttf');
}

@font-face {
  font-family: 'Avenir Medium';
  src: url('../fonts/Avenir-Medium.ttf');
}

@font-face {
  font-family: 'avenir_bold';
  src: url('../fonts/AvenirLTStd-Black.otf');
}

body {
  font-family: 'avenir';
  user-select: none;
  overscroll-behavior-y: contain;
}

img {
  -webkit-user-drag: none;
  -moz-user-select: none;
}

.text-avenir-heavy {
  font-family: 'Avenir Heavy';
}

.text-avenir-medium {
  font-family: 'Avenir Medium';
}

.text-avenir_bold {
  font-family: 'avenir_bold';
}

.text-hochelaga {
  font-family: 'hochelaga';
}

.text-dark-grey {
  color: $DarkGrey;
}

.text-Vanilla {
  color: $Vanilla;
}

.font-max-bold {
  font-weight: 900;
}

.font-semi-max-bold {
  font-weight: 800;
}

.bg-green {
  background-color: $Green;
}

.bg-rose-white {
  background-color: $RoseWhite;
}

.lavender-grey-border {
  border-color: $LavenderGrey !important;
}

.text-xs-max {
  font-size: 15px;
}

.btn-fill-green {
  color: $Black;
  background: transparent;
  border-color: transparent;

  &:hover {
    color: $Black;
    border-color: $Linen;
    background-color: $Linen;
  }

  &:focus {
    color: $Black;
    background-color: $Linen;
    border-color: $Linen;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px rgba(0, 98, 65, 0.5);
  }
}

.btn-green {
  color: $white;
  background: $Green;
  border-color: $Green;

  &:hover {
    color: $Green;
    border-color: $Green;
    background-color: transparent;
  }

  &:focus {
    color: $Green;
    background-color: transparent;
    border-color: $Green;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px rgba(0, 98, 65, 0.5);
  }
}

.alteryx-btn {
  color: $white;
  background: $AlteryxBlue;
  border-color: $AlteryxBlue;

  &:hover {
    color: $AlteryxBlue;
    border-color: $AlteryxBlue;
    background-color: transparent;
  }

  &:focus {
    color: $AlteryxBlue;
    background-color: transparent;
    border-color: $AlteryxBlue;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px rgba(12, 38, 59, 0.5);
  }
}

.btn-outline-white {
  color: $white;
  background: transparent;
  border-color: $white;

  &:hover {
    color: $Black;
    border-color: $white;
    background-color: $white;
  }

  &:focus {
    color: $white;
    background-color: transparent;
    border-color: $white;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px rgba(255, 255, 255, 0.5);
  }
}

.btn-outline-black {
  color: $Black;
  background: transparent;
  border-color: $Black;

  &:hover {
    color: $white;
    border-color: $Black;
    background-color: $Black;
  }

  &:focus {
    color: $white;
    background-color: $Black;
    border-color: $Black;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px rgba(255, 255, 255, 0.5);
  }
}

.btn-outline-alteryx {
  color: $AlterBlue;
  background: transparent;
  border-color: $AlterBlue;

  &:hover {
    color: $white;
    border-color: $AlterBlue;
    background-color: $AlterBlue;
  }

  &:focus {
    color: $white;
    background-color: $AlterBlue;
    border-color: $AlterBlue;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px rgba(12, 38, 59, 0.5);
  }
}

.btn-outline-midnight-blue {
  color: $MidnightBlue;
  background: transparent;
  border-color: $MidnightBlue;

  &:hover {
    color: $white;
    border-color: $MidnightBlue;
    background-color: $MidnightBlue;
  }

  &:focus {
    color: $white;
    background-color: $MidnightBlue;
    border-color: $MidnightBlue;
    box-shadow: 0 1px 1px rgba(11, 7, 50, 0.075), 0 0 0 3px rgba(11, 7, 50, 0.5);
  }
}

.scroll-hide {
  &::-webkit-scrollbar {
    width: 0px;
  }
}

.btn-transparent {
  background-color: transparent;
}

.bg-transparent {
  background-color: transparent;
}

.bg-linen {
  background-color: $Linen;
}

.bg-vista-white {
  background-color: $VistaWhite;
}

.input-border-bottom {
  border-bottom: 1px solid $Black !important;
}

.black-box-shadow {
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
}

.input-border {
  border: 1px solid $Black;

  &:focus {
    border: 1px solid $Black;
  }
}

// .border color
.border-platinum {
  border-color: $Platinum;
}

// cursor
.cursor-arrow {
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 9;
  width: 25px;
  height: 25px;
  margin: 10px;
  font-size: 12px;
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
}

.patterns-text {
  width: 106px;
  height: 39px;
  text-align: center;
}