@import "../../assets/scss/variable.scss";

.Navbar {

  .logo {
    img {
      max-width: 185px;
    }
  }

  .img {
    margin-right: 10px;
  }

  .topnav-centered {
    float: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: $fw-600;

    .nav-link {
      color: $MidnightBlue;
      font-size: $fs-13;
      text-transform: uppercase;
      letter-spacing: 1.6px;

      img {
        padding: 0px 5px;
        margin-top: -5px;
      }
    }
  }

  .btn {
    border-radius: $br-20;
    border: solid 1px $MidnightBlue;
    width: 100px;
    margin-left: 10px;
    font-weight: $fw-600;
    background-color: $RoseWhite;
    font-size: $fs-15;
  }

  .navbar-toggler {
    outline: none;
    border: none;

    &:focus {
      text-decoration: "none";
      box-shadow: none;
    }
  }
  &.alteryx-header-color{
    
    background-color: $AlteryxBlue !important;
    .topnav-centered .nav-link{
      color: $RoseWhite !important;
    }
    .btn{
    background-color: $AlteryxBlue !important;
    color: $RoseWhite !important;
    border-color: $RoseWhite !important;

    }
    .dropdown-toggle{
      &::after{
        color: transparent;
      }
      img{
        filter: invert(1);
      }
    }
  }
  &.wolly-header-color{
    background-color: $RoseWhite;

  }
}

.show>.btn-primary.dropdown-toggle {
  color: $RoseWhite;
  background-color: $RoseWhite;
  border-color: $RoseWhite;
}

.btn-primary.dropdown-toggle {
  color: $RoseWhite;
  background-color: $RoseWhite;
}

.droupdown {
  background-color: $RoseWhite;

}

.navbar-menu {
  .dropdown-toggle {
    border: none;
    box-shadow: none;
  }

  button {
    &::after {
      border: none !important;
    }
  }

  .dropdown-menu {
    background-color: $Linen;
    right: 0;
    left: auto;
    padding-top: 18px;
    padding-bottom: 20px;
  }
}
.dropdown-menu {
  a {
    .modifications {
      width: 20px;
    }
}  
}
@media only screen and (max-width: 767.98px) {
  .Navbar {
    .logo {
      display: none;
    }

    .dropdown {
      #dropdown-custom-components {
        margin-left: 0px;

        &:focus {
          box-shadow: none;
          border-color: none;
        }
      }
    }

    .show>.btn-primary.dropdown-toggle {
      background-color: $RoseWhite;
      border-color: none;
      box-shadow: none;

      &:focus {
        box-shadow: none;
        border-color: none;
      }
    }
  }

  .responsive-lg {
    display: none;
  }

  .dropdown-menu {
    border: none;
    background-color: $Linen;
    width: fit-content;
    border-radius: 5px;
    margin-left: 10px;
    padding: 5px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    a {
      margin: 0px 0px;
      .modifications {
        width: 25px;
      }

      img {
        width: 25px;
        height: 20px;
        padding-right: 5px;
      }
    }
  }
  .navbar-menu {
    .dropdown-menu {
      right: auto;
      margin-left: 0;
      padding: 5px 5px 10px 5px; 
    }
  }
  .Navbar{
    &.wolly-header-color {
      background-color: #ffffff;
    }
  }
}

@media only screen and (max-width: 991px) {
  .navbar-menu {
    .half-drop-down {
      .dropdown-menu {
        right: 0;
        left: 0;
        top: 100%;
      }

      button {
        &::after {
          filter: invert(1);
        }
      }
    }
  }
}