// project Selection screen stylesheet
.flex-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fbf8f3;
  height: 100%;
  min-height: 100vh; //bg-color added for transparent image
  padding: 20px 0;
}

.image-gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  padding: 0;
}

.image-gallery>li {
  flex-basis: 350px;
  /* width: 350px; */
  list-style: none;
}

.image-gallery li img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  vertical-align: middle;
  border-radius: 20px;
  background-color: #fbf8f3; //bg-color added for transparent image
}

.image-gallery li img:hover {
  // filter: drop-shadow(0 0 0.75rem crimson);
  -webkit-box-shadow: 0 2px 10px 0 rgba(0, 0, 0, .2), 0 7px 72px 0 rgba(0, 0, 0, .18);
  box-shadow: 0 2px 10px 0 rgba(57, 57, 57, 0.2), 0 7px 72px 0 rgba(53, 53, 53, 0.18);
  top: -10px;
  transition: 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.inspiration-image-gallery:hover {
  border-radius: 20px;
  // filter: drop-shadow(0 0 0.75rem crimson);
  -webkit-box-shadow: 0 2px 10px 0 rgba(0, 0, 0, .1), 0 7px 72px 0 rgba(0, 0, 0, .18);
  box-shadow: 0 2px 10px 0 rgba(57, 57, 57, 0.1), 0 7px 72px 0 rgba(53, 53, 53, 0.18);
  // top: -10px;
  transition: 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
}