$Green:#006241;
$Goldenrod:#F0CC7C;
$BrownSugar:#E7AC79;
$BrightGrey:#46454C;
$Black:#000000;
$Linen:#F5F0E7;
$LavenderGrey:#C9C9C9;
$VistaWhite:#FBF8F4;
$white:#FFFFFF;
$Platinum:#E1E1E1;
$DarkGrey:#343A40;
$Vanilla:#C7BFB0;
$RoseWhite:#FBF8F3;
$MidnightBlue:#0B0732;
$PeachOrange:#F1D0A7;
$PaleBlueLily:#CCEEEE;
$LightGrey:#D9D9D9;
$RubberDuckyYellow:#ffd100;
$SilverChalice:#ACA9A2;
$AlteryxBlue:#0C263B;
$AlterBlue:#1C71A4;
//
// Color
//
$circle-border: #CFCFCF;
$box-shadow: #040303;
$btn-hover: #CEECDC;
//
// Font Size
$fs-10: 10px;
$fs-12: 12px;
$fs-13: 13px;
$fs-14: 14px;
$fs-15: 15px;
$fs-17: 17px;
$fs-18: 18px;
$fs-20: 20px;
$fs-24: 24px;
//
//
// Font Weight
$fw-300: 300;
$fw-400: 400;
$fw-500: 500;
$fw-600: 600;
$fw-700: 700;
$fw-800: 800;
$fw-900: 900;
//

//
// Border Radius
//
$br-20: 20px;
$br-30: 30px;
