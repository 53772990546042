@import "../../assets/scss/variable.scss";

@font-face {
  font-family: "Avenir";
  src: local("AvenirLTStd-Book"),
    url("../../assets/fonts/avenir_ff/AvenirLTStd-Book.otf") format("truetype");
}

@font-face {
  font-family: "Hochelaga";
  src: local("Hochelaga-Regular"),
    url("../../assets/fonts/Hochelaga-Regular.otf") format("truetype");
}

.modal {
  z-index: 1050;

  .modal-content {
    background-color: $RoseWhite;

    .modal-header {
      justify-content: center;
      border-bottom: none;

      .btn-close {
        padding: 0;
        margin: 0;
        opacity: 1;
      }

      span {
        font-size: 20px;
      }

      .modal-title {
        left: 50%;
      }
    }

    .modal-body {
      width: 100%;
      text-align: center;
      padding: 0 2.5rem 2.5rem 2.5rem;

      .main-title {
        justify-content: center;
        padding: 2rem 3rem;
        font-weight: $fw-400;
        font-size: $fs-24;
      }

      .btn-done-modal {
        border-radius: $br-30 !important;
        box-shadow: none;
        font-size: $fs-17;
        text-align: left;
        margin: 5px 0px;
        padding: 10px 30px;

        &:hover {
          color: $MidnightBlue !important;
          background-color: $btn-hover;
        }

        img {
          width: 25px;
          height: 25px;
          margin-right: 5px;
        }
      }

      .link {
        display: grid;
        margin: auto;

        .link-title {
          font-weight: $fw-600;
          font-size: $fs-20;
        }

        .line {
          border-bottom: solid 2px;
          line-height: 3;
        }

        .copy-link {
          font-weight: $fw-800;
          font-size: $fs-14;
          background-color: $btn-hover;
          border-radius: $br-20;
        }
      }

      .solution-row-bottom .solution-row-bottom .solution-col-first {
        width: 50%;
        float: left;
        text-align: center;
      }

      .solution-row-bottom .solution-row-bottom .solution-col-second {
        width: 50%;
        float: left;
        display: flex;
        align-items: center;
        padding: 0 2rem;
      }

      .carousel {
        padding-bottom: 2.5rem;

        .carousel-indicators {
          margin-bottom: 0;
          // bottom: -10px;
        }

        .carousel-indicators [data-bs-target] {
          width: 11px;
          height: 11px;
          border-radius: 50%;
          background-color: #D9D9D9;
          margin-left: 23px;
          opacity: 1;
        }

        .carousel-indicators .active {
          background-color: #000000 !important;
        }

        .carousel-control-prev-icon {
          width: 30px;
          height: 30px;
          background-image: url("../../assets/images/left_arrow.svg");
          position: relative;
          top: 3px;
        }

        .carousel-control-next-icon {
          width: 30px;
          height: 30px;
          background-image: url("../../assets/images/right_arrow.svg");
          position: relative;
          top: 3px;
        }
      }

      .information {
        text-align: initial;
      }

      .row {
        h5 {
          text-align: initial;
        }

        .col {
          .carousel-caption {
            color: $MidnightBlue;
            position: relative;
            left: 0;
            right: 0;
            text-align: initial;
            padding-bottom: 0;
          }
        }
      }
    }
  }
}

.inspiration-col {
  position: relative;

  .inspiration-button-radius {
    border-radius: 50px;
    font-family: "Avenir";
    font-weight: 800;
  }

  .inspiration-button {
    height: 100%;
    width: 100%;
    display: none;
    align-items: center;
    justify-content: center;
    position: absolute;
    inset: 0px;
  }

  &:hover {
    .inspiration-button {
      display: flex;
    }
  }

  .save-img {
    height: 256px;
  }
}

.inspiration-conform {
  .modal-title {
    font-family: "Hochelaga";
  }

  .modal-content {
    border-radius: 10px;
    font-family: "Avenir";
    margin: 0 35px;
  }

  .border-top {
    .row {
      button {
        font-family: "Avenir";
        font-weight: 500;
        color: #000000;
        font-size: 17px;
      }
    }
  }
}

.inspiration-modal-btn {
  background-color: $RoseWhite;
  color: black;

  &:hover {
    background-color: $RoseWhite;
    color: black;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: flex-end !important;
  justify-content: center;
  width: 30px !important;
  height: 30px !important;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 1;
  transition: opacity .15s ease;
  height: 15px;
  bottom: 4px;
  top: inherit;
  right: 0;
}

.carousel-control-prev {
  z-index: 9;
  left: 40%;
}

.carousel-control-next {
  z-index: 9;
  right: 37%;
}

.inspirationModal-text {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
}

.modal .modal-content .modal-body .main-title {
  padding: 2rem 1rem;
}

.content-font {
  font-family: "Avenir";
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
}

.content-title-font {
  font-family: "Hochelaga";
}

@media screen and (max-width: 991.98px) {
  .carousel-img {
    img {
      height: auto;
    }

    iframe {
      height: 300px;
    }
  }

  .carousel-control-prev {
    left: 35%;
  }

  .carousel-control-next {
    right: 30%;
  }
}

@media screen and (max-width: 576px) {

  .color-circle-Thread {
    margin-bottom: 1.5rem;
  }

  .inspiration-col {
    .inspiration-button {
      display: flex;
    }
  }

  .carousel-control-prev {
    left: 32%;
  }

  .carousel-control-next {
    right: 26%;
  }
}

@media screen and (max-width: 420px) {
  .carousel-control-prev {
    left: 28%;
  }

  .carousel-control-next {
    right: 20%;
  }
}

@media only screen and (max-width: 767px) {
  .modal {
    .modal-content {
      .modal-body {
        .btn-done-modal {
          // text-align: center;
          padding-left: 50px;
        }

        .main-title {
          padding: 2rem 0rem 1rem 0rem;
        }
      }
    }
  }
}

@media only screen and (max-width: 993px) {
  .modal {
    .modal-content {
      .main-title {
        text-align: left;
      }

      .modal-body {
        .carousel-row {
          display: block;

          .h5-block {
            font-weight: bold;
            text-align: initial;
          }
        }

        .main-title {
          padding: 2rem 0rem 1rem 0rem;
        }
      }
    }
  }
}

.modal-image {
  height: 200px;
  width: 300px;
  margin: auto;
}

.share-modal-image {
  margin: auto;
}

.skeleton-box {
  display: inline-block;
  height: 1em;
  position: relative;
  overflow: hidden;
  background-color: #DDDBDD;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(90deg,
        rgba(#fff, 0) 0,
        rgba(#fff, 0.2) 20%,
        rgba(#fff, 0.5) 60%,
        rgba(#fff, 0));
    animation: shimmer 5s infinite;
    content: '';
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
}